'use client'

import { useCallback, useMemo, useRef, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import useNavigateAfterSignIn from 'actions/auth/navigateAfterSignIn/useNavigateAfterSignIn'

export interface AfterSignInNavigationOptions {
  fallbackToHome?: boolean
  skipSuccessPage?: boolean
}

/**
 * This hook can be used to a) determine if a signed user requires
 * additional sign in steps and b) handling the redirection based on
 * that data.
 */
function useNextAuthStep() {
  const searchParams = useSearchParams()
  const navigating = useRef(false)
  const pathname = usePathname()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  /**to
   * Mutation: navigate to next screen
   */
  const {
    mutateAsync: navigateAfterSignInMutation,
    isPending: isNavigatingPending,
  } = useNavigateAfterSignIn()

  /**
   * Reload data and navigate to the next screen
   */
  const navigateAfterSignIn = useCallback(async (options?: AfterSignInNavigationOptions) => {
    if (navigating.current) return

    navigating.current = true

    const result = await navigateAfterSignInMutation({
      ...options,
      currentPath: pathname,
      serializedSearchParams: searchParams.toString(),
    })

    if (result?.errorMessage) setErrorMessage(result.errorMessage)
  }, [navigateAfterSignInMutation, pathname, searchParams])

  //
  // Return
  return useMemo(() => ({
    isLoading: isNavigatingPending,
    navigateAfterSignIn,
    errorMessage,
  }), [isNavigatingPending, navigateAfterSignIn, errorMessage])
}

export default useNextAuthStep
